export default {
	defaultCountryId: 120,
	defaultCountryName: "Nederland",
	defaultTaxPercentage: 21,
	zeroVatPercentage: 0,
	periodicInvoiceNumber: "Periodiek",
	pdfExtension: "pdf",
	validImageExtensions: ["gif", "jpg", "jpeg", "png"],
	accountSettingsTabs: {
		bank: 4
	},
	ranges: {
		currency: {
			min: 0,
			max: 1000000
		}
	},
	botTriggers: {
		none: 0,
		zeroVat: 1,
		holidays: 14,
	},
	generalTransactionTypes: {
		unknown: 0,
		guaranteePayment: 1,
		vatPayment: 2,
		currentAccountPrivate: 3,
		currentAccountOtherBusiness: 4,
		interestPayment: 5,
		corporateTax: 6,
		vacationPay: 7,
		dividendTax: 8,
		currentAccountSameBusiness: 10,
		loanPayment: 88
	},
	bookingColumns: {
		bookingNumber: 0,
		date: 1,
		ledger: 2,
		description: 3,
		total: 4,
	},
	relationTypes: {
		unknown: 0,
		supplier: 1,
		prospect: 2,
		customer: 3,
		general: 4,
	},
	vatReturnTypes: {
		vatReturn: 1,
		supplementation: 2
	},
	lockReasons: {
		none: 0,
		ban: 1,
		deactivation: 2,
		vat: 3,
		incomeTax: 4
	},
	lockReasonTypes: {
		none: { id: 0, name: "Alles" },
		ban: { id: 1, name: "Blokkade (Tijdelijk)" },
		deactivation: { id: 2, name: "Inactief" },
		vat: { id: 3, name: "Freeze OB" },
		incomeTax: { id: 4, name: "Freeze IB" }
	},
	quotationStatuses: [
		{ id: 1, name: "Open" },
		{ id: 2, name: "Verlopen" },
		{ id: 3, name: "Afgekeurd" },
		{ id: 4, name: "Goedgekeurd" },
		{ id: 5, name: "Gefactureerd" }
	],
	taxTimeSlots: {
		quarterly: 2,
		yearly: 3,
		smallBusinessesScheme: 4,
		vatExemptedNoVat: 5,
		vatExemptedHasVat: 6,
		vatExemptedPartially: 7
	},
	taxMandateTypes: {
		perpetualVia: 1
	},
	taxMandateStatus: {
		unknown: 0,
		created: 1,
		sending: 2,
		checking: 3,
		uploaded: 4,
		active: 5,
		inActive: 6,
		rejected: 7,
		failed: 8,
		verifyingCode: 9
	},
	transactionFormTypes: {
		modal: 0,
		panel: 1,
		assetModal: 2
	},
	transactionStatuses: [
		{ id: 1, name: "Concept" },
		{ id: 2, name: "Gepland" },
		{ id: 3, name: "Onbetaald" },
		{ id: 4, name: "Betaald" },
		{ id: 5, name: "Oninbaar" },
	],
	transactionTypes: {
		investments: 9,
		revenue: 14,
		reimbursements: 15,
		revenueOther: 16,
	},
	paymentMethods: {
		manual: 0,
		iDeal: 1,
		recurring: 2,
		other: 3
	},
	periodTypes: {
		currentYear: 1,
		currentQuarter: 2,
		previousQuarter: 3,
		all: 4,
		selectYear: 5,
		selectQuarter: 6,
		selectMonth: 7,
		yearToDate: 8,
		custom: 9
	},
	balanceItemStatus: {
		unknown: 0,
		new: 1,
		active: 2,
		inactive: 3,
		changed: 4,
		sold: 5,
		broken: 6,
		private: 7,
		writtenOff: 8,
		split: 9
	},
	balanceSpecifications: {
		car: 1,
		computer: 2,
		tablet: 3,
		phone: 4,
		building: 5,
		other: 6,
		cameraEquipment: 7,
		bike: 8
	},
	transactionGroupStatus: {
		none: 0,
		concept: 1,
		planned: 2,
		open: 3,
		paid: 4,
		uncollectible: 5,
		partiallyPaid: 6
	},
	transactionSpecifications: {
		car: 16,
		revenueServices: 29,
		revenueGoods: 30
	},
	templateTypes: {
		invoice: 1,
		quotation: 2,
		expense: 3
	},
	templateTextTypes: {
		invoiceEmail: 1,
		quotationEmail: 2
	},
	incomeTaxReturnStatus: {
		none: 0,
		prepareListOpen: 1,
		prepareListSend: 2,
		prepareListDone: 3,
		questionnaireOpen: 4,
		questionnaireSend: 5,
		questionnaireDone: 6,
		processing: 7,
		inConcept: 8,
		approved: 9,
		submitted: 10
	},
	vatReturnStatuses: {
		absent: 0,
		open: 1,
		toBeCalculated: 2,
		reviewingForced: 3,
		reviewing: 4,
		concept: 5,
		approved: 6,
		submitted: 7,
		selfSubmitted: 8,
		sending: 9,
		failed: 10,
		confirmedWithCustomer: 11,
		paid: 12,
		onHold: 13,
		toBeChecked: 14,
		obsolete: 15,
		notFiled: 16
	},
	vatReturnActions: {
		none: 0,
		create: 1,
		moveToCalculate: 2,
		moveToCalculateByForce: 3,
		moveToConcept: 4,
		moveToApprove: 5,
		moveToSubmitted: 6,
		moveToSelfSubmitted: 7,
		moveToSending: 8,
		moveToReviewingByCalculating: 9,
		toggleEnableAutoCorrections: 10,
		reopen: 11,
		restoreAfterSendingFailed: 12,
		moveToDoNotFileTaxReturn: 13
	},
	vatReturnStatusItems: [
		{ id: null, name: "Alles" },
		{ id: 0, name: "Niet aanwezig" },
		{ id: 1, name: "Open" },
		{ id: 2, name: "Te berekenen" },
		{ id: 3, name: "Ter controle (Geforceerd)" },
		{ id: 4, name: "Ter controle" },
		{ id: 5, name: "Concept" },
		{ id: 6, name: "Akkoord" },
		{ id: 7, name: "Verstuurd" },
		{ id: 8, name: "Zelf Verstuurd" },
		{ id: 9, name: "Bezig met verzenden" },
		{ id: 10, name: "Gefaald" },
		{ id: 11, name: "Notificatie gestuurd naar klant" },
		{ id: 12, name: "Betaald door klant" },
		{ id: 13, name: "Heeft open opmerking" },
		{ id: 14, name: "Klaargezet voor controle" },
		{ id: 15, name: "Verlopen" },
		{ id: 16, name: "Geen aangifte gedaan" },
	],
	todoTypes: {
		manual: 0,
		guideToCompany: 1,
		guideToPaymentTerm: 2,
		guideToItems: 3,
		guideToGoals: 4,
		vatReturnReady: 5,
		vatReturnConcept: 6,
		notificationAccountant: 7,
		vatReturnPaid: 8,
		guideToTemplates: 9,
		incomeTaxPrepareList: 10,
		incomeTaxUploadAuthorization: 11,
		incomeTaxQuestionnaire: 12,
		incomeTaxConcept: 13,
		invoiceNeedsReceipt: 14,
		invoiceDueDate: 15,
		previousIncomeTax: 16,
		taxMandate: 17,
		recurringPaymentFailed: 18,
		previousInvestments: 20,
		mainAdministrationInvoiceTodo: 21,
		previousVatReturn: 22,
		preliminaryIncomeTaxQuestionnaire: 23,
		preliminaryIncomeTaxConcept: 24
	},
	adminReports: {
		transactionsIncomeTax: "ReportTransactionsIB",
		IncomeTaxPersons: "ReportIbPersons"
	},
	adminTodoTypes: {
		identification: 1,
		accountDeletion: 2,
		accountNotDeleted: 3,
		taxMandates: 4,
		incomeTaxPartnerInvoices: 5,
		vatReturn: 6,
		incomeTaxReturn: 7,
		manual: 8,
		investments: 9,
		processBalance: 10,
		swipes: 11,
		korTurnover: 12,
		snoozeIncomeTaxTodo: 13,
		processPreviousVatReturn: 14,
		horizontalSupervisionExport: 15,
		duplicatePersons: 16,
		preliminaryIncomeTaxReturnProcess: 17,
		preliminaryIncomeTaxReturnSubmit: 18
	},
	auditStatus: {
		unseen: 0,
		approved: 1,
		rejected: 2
	},
	todoPriorities: {
		unknown: 0,
		high: 1,
		medium: 2,
		low: 3,
	},
	roles: {
		user: "user",
		admin: "admin",
		developer: "developer",
		accountant: "accountant"
	},
	http: {
		method: {
			get: "GET",
			put: "PUT",
			post: "POST",
			delete: "DELETE"
		}
	},
	html: {
		checked: "<i class=\"fal fa-check\"></i>",
		unchecked: "<i class=\"fal fa-times\"></i>"
	},
	targets: {
		0: "Expense",
		expense: "Expense",
		1: "Revenue",
		revenue: "Revenue",
		2: "Other",
		other: "Other",
		plural: {
			Revenue: "revenues",
			Expense: "expenses"
		}
	},
	datePickerModes: {
		all: 0,
		quarter: 1,
		year: 2
	},
	reference: {
		expense: 0,
		revenue: 1,
		general: 2
	},
	amountTypes: {
		positive: 1,
		negative: 2
	},
	goalDays: {
		0: "Maand",
		1: "Kwartaal",
		2: "Half jaar",
		3: "Jaar"
	},
	icpReturnLineStatus: {
		none: 0,
		checking: 1,
		valid: 2,
		invalid: 3,
		error: 4
	},
	icpReturnStatuses: {
		absent: 0,
		open: 1,
		concept: 2,
		approved: 3,
		sending: 4,
		failed: 5,
		submitted: 6
	},
	companyTypes: {
		zzp: 1,
		vof: 2,
		bv: 3,
	},
	bvCompanyTypes: [
		{ id: 1, name: "Holding" },
		{ id: 2, name: "Werkmaatschappij" },
	],
	tagGroups: {
		companyTags: 1,
		onboarding: 2
	},
	hourCriterion: 1225,
	rideRegistrationTypes: {
		none: { value: 1, description: "Geen rittenregistratie" },
		simple: { value: 2, description: "Eenvoudige rittenregistratie" },
		complete: { value: 3, description: "Sluitende rittenregistratie" },
		corporate: { value: 4, description: "Bestelauto / verklaring uitsluitend zakelijk gebruik" },
	},
	propertyTypes: {
		text: 1,
		number: 2,
		boolean: 3,
	},
	quarters: [
		{ text: "Q1", value: 1, startDate: { day: 1, month: 1 }, endDate: { day: 31, month: 3 } },
		{ text: "Q2", value: 2, startDate: { day: 1, month: 4 }, endDate: { day: 30, month: 6 } },
		{ text: "Q3", value: 3, startDate: { day: 1, month: 7 }, endDate: { day: 30, month: 9 } },
		{ text: "Q4", value: 4, startDate: { day: 1, month: 10 }, endDate: { day: 31, month: 12 } },
	],
	invoicingTypes: {
		postInvoicing: 1,
		preInvoicing: 2
	},
	invoicingTypeOptions: [
		{ id: 1, name: "Achteraf factureren" },
		{ id: 2, name: "Vooraf factureren" },
	],
	recurringTypes: [
		{ id: 1, name: "Eenmalig" },
		{ id: 2, name: "Wekelijks" },
		{ id: 6, name: "Tweewekelijks" },
		{ id: 3, name: "Maandelijks" },
		{ id: 4, name: "Per kwartaal" },
		{ id: 7, name: "Per half jaar" },
		{ id: 5, name: "Per jaar" },
	],
	recurringPeriodTypes: [
		{ code: null, name: "Geen periodieke registratie" },
		{ code: "weekly", name: "Wekelijks" },
		{ code: "monthly", name: "Maandelijks" },
		{ code: "quarterly", name: "Per kwartaal" },
		{ code: "halfYearly", name: "Halfjaarlijks" },
		{ code: "daysOfWeek", name: "Op vaste dagen" }
	],
	vatTypes: [
		{ id: 0, name: "0%" },
		{ id: 1, name: "21%" },
		{ id: 2, name: "9%" },
		{ id: 3, name: "Btw vrijgesteld" },
		{ id: 4, name: "Btw buiten EU" },
		{ id: 5, name: "Btw binnen EU" }
	],
	vatTypesWithoutExempt: [
		{ id: 0, name: "0%" },
		{ id: 1, name: "21%" },
		{ id: 2, name: "9%" },
		{ id: 4, name: "Btw buiten EU" },
		{ id: 5, name: "Btw binnen EU" }
	],
	auditVatTypes: {
		vatZero: 0,
		vatHigh: 1,
		vatLow: 2,
		vatExempt: 3,
		vatOutsideEu: 4,
		vatInsideEu: 5
	},
	vatPercentages: [21, 9, 0],
	vatTypeOptions: {
		percentage: 0,
		exempted: 1,
		foreignOutsideEu: 2,
		foreignInsideEu: 3
	},
	documentFileTypes: {
		previousIbFile: 1,
	},
	hourRegistrationTabs: {
		table: 1,
		agenda: 2,
	},
	companyScoreTypes: [
		{ id: 1, name: "Handmatig" },
		{ id: 2, name: "Initieel" },
		{ id: 3, name: "Openstaande OB aangifte" },
		{ id: 4, name: "Concept OB aangifte" },
		{ id: 5, name: "IB vragenlijst" },
		{ id: 6, name: "Notities behandelen" },
		{ id: 7, name: "Betaalherinneringen" },
		{ id: 8, name: "Transactie toevoegfrequentie" },
	],
	incomeTaxInvoiceStatuses: [
		{ id: 0, name: "Alles" },
		{ id: 1, name: "Open" },
		{ id: 2, name: "Gefactureerd" }
	],
	invoiceFrequencies: {
		once: 1,
		weekly: 2,
		monthly: 3,
		quarterly: 4,
		yearly: 5,
		biweekly: 6,
		halfYearly: 7
	},
	kvkStatuses: {
		initial: 0,
		incorrect: 1,
		error: 2,
		notFound: 3,
		success: 4
	},
	transactionTypeTargets: [
		{ id: 0, name: "Uitgaven" },
		{ id: 1, name: "Inkomsten" },
		{ id: 2, name: "Memoriaal" }
	],
	hourRegistrationRecurrenceTypes: {
		none: { name: "Niet herhalen", value: 0 },
		weekly: { name: "Wekelijks", value: 1 },
		monthly: { name: "Maandelijks", value: 2 },
		quarterly: { name: "Per kwartaal", value: 3 },
		halfYearly: { name: "Halfjaarlijks", value: 4 },
		daysOfWeek: { name: "Op vaste dagen", value: 5 }
	},
	daysOfWeek: [
		{ name: "Maandag", value: 1 },
		{ name: "Dinsdag", value: 2 },
		{ name: "Woensdag", value: 3 },
		{ name: "Donderdag", value: 4 },
		{ name: "Vrijdag", value: 5 },
		{ name: "Zaterdag", value: 6 },
		{ name: "Zondag", value: 0 },
	],
	agendaColors: [
		"primary",
		"primary-light",
		"yellow",
		"orange",
		"grey",
		"pink"
	],
	bookingStatus: {
		none: 0,
		concept: 1,
		planned: 2,
		open: 3,
		paid: 4,
		uncollectible: 5
	},
	balanceSide: {
		debit: 1,
		credit: 2
	},
	financialStatementType: {
		openingBalanceSheet: 1,
		balanceSheet: 2,
		profitAndLoss: 3
	},
	ledgers: {
		general: 1,
		sales: 2,
		purchases: 3,
		bank: 4,
	},
	ledgerAccountTypes: {
		cash: 1,
		revenueServices: 2,
		revenueGoods: 3,
		revenueOther: 4,
		liquidAssets: 5,
		expenses: 6,
		results: 7,
		taxes: 9,
		debtor: 10,
		creditor: 11,
		vatReceivable: 12,
		vatPayable: 13,
		otherReceivables: 14,
		provisions: 15,
		fixedAssets: 16,
		depreciations: 17,
		inventory: 18,
		equity: 19,
		resultToBeDistributed: 20,
		debt: 21,
		other: 22,
		currentAssets: 23
	},
	paymentAccountType: {
		bankAccount: 1,
		mollieAccount: 2,
		paypalAccount: 3,
		stripeAccount: 4,
	},
	invoiceTypes: {
		generatedInvoice: 0,
		thirdPartyInvoice: 1,
		creditInvoice: 2
	},
	bookkeepingTypes: {
		single: 1,
		double: 2
	},
	integratedTransactionType: {
		transaction: 1,
		bookingProposition: 2,
		bankTransaction: 3,
		paymentProvider: 4,
	},
	bankActions: {
		none: 0,
		updateTransaction: 1,
		createNewTransaction: 2,
		ignore: 3,
		linkSuggestion: 4
	},
	bankStatuses: {
		open: 0,
		processed: 1
	},
	bankTransactionType: {
		debit: 0,
		credit: 1
	},
	integratedTransactionStatus: {
		done: 1,
		notNeeded: 2,
		partiallyDone: 3,
		notDone: 4
	},
	defaultPaymentTerms: {
		expense: 30,
		invoice: 30
	},
	transactionLinkStatuses: [
		{ text: "Onverwerkt", value: 4 },
		{ text: "Verwerkt", value: 1 }
	],
	fileStatuses: [
		{ text: "Aanwezig", value: 1 },
		{ text: "Niet nodig", value: 2 },
		{ text: "Afwezig", value: 4 }
	]
};